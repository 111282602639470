<!--
 * @Description: 简历库主页
 * @Author: huacong
 * @Date: 2021-04-26 13:53:54
 * @LastEditTime: 2021-08-06 09:25:42
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <!-- 搜索框 -->
      <div class="searchBox">
        <el-input
          placeholder="输入职位关键词，如“一级建造师”等"
          class="searchInput"
          v-model="searchCer"
        >
          <template slot="append">
            <div @click="postSearch">
              <img class="mini-icon" :src="searchIcon" alt="" />
              <span class="mini-icon" style="top: -2px;">搜索</span>
            </div>
          </template>
        </el-input>
        <el-button
          type="primary"
          class="radius-25"
          style="width:120px;margin-left:16px;"
          v-prevent-repeat-click
          @click="postBtn"
          v-if="userType"
          >发布职位</el-button
        >
        <p style="margin: 10px 44px;">
          <!-- <span style="color: #999">热门岗位：</span> -->
        </p>
      </div>
      <!-- 切换职位类型 -->
      <div style="margin-bottom: 20px;margin-top: 20px;">
        <el-radio-group
          v-model="workType"
          size="small"
          @change="changeWorkType"
        >
          <el-radio-button label="兼职简历"></el-radio-button>
          <el-radio-button label="全职简历"></el-radio-button>
          <!-- <el-radio-button label="猎头荐才"></el-radio-button> -->
        </el-radio-group>
      </div>
    </section>
    <fulltime v-show="worktype1" ref="full"></fulltime>
    <parttime v-show="worktype2" ref="part"></parttime>
    <recomme v-show="worktype3" ref="recomme"></recomme>
  </div>
</template>
<script>
import fulltime from "./fulltime/fulltime-element.vue";
import parttime from "./parttime/parttime-element.vue";
import recomme from "./recomme/recomme-element.vue";
import $ from "jquery";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    fulltime: fulltime,
    parttime: parttime,
    recomme: recomme,
  },
  data() {
    return {
      searchIcon: require("@/assets/icon/search-icon.png"),
      searchCer: "",
      workType: "兼职简历",
      worktype1: false,
      worktype2: false,
      worktype3: true,
      userType: false,
    };
  },
  computed: {
    ...mapState(["comId"]),
    userInfo() {
      let k = this.$store.state.userInfo;
      if (JSON.stringify(k) == "{}") {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    ...mapMutations(["setcomRealeInfo", "setcomStaffInfo", "setcomReale"]),
    changeWorkType(e) {
      if (e === "全职简历") {
        this.worktype1 = true;
        this.worktype2 = false;
        this.worktype3 = false;
        // this.searchCer = "";
      } else if (e === "兼职简历") {
        this.worktype1 = false;
        this.worktype2 = false;
        this.worktype3 = true;
        // this.searchCer = "";
      }
    },
    postSearch() {
      if (this.workType == "全职简历") {
        this.$refs.full.searchInfo.position_certificate = this.searchCer;
        this.$refs.full.cerList.forEach((element) => {
          if (this.searchCer == element.category_name) {
            this.$refs.full.majorList = element.child;
          }
        });
        this.$refs.full.searchInfo.position_major = "";
        $(".selmajorBtn").removeClass("active");
        this.$refs.full.getParttimeList();
      } else if (this.workType == "兼职简历") {
        this.$refs.recomme.searchInfo.certificate = this.searchCer;
        this.$refs.recomme.cerList.forEach((element) => {
          if (this.searchCer == element.certificate) {
            this.$refs.recomme.majorList = element.child;
          }
        });
        this.$refs.recomme.searchInfo.major = "";
        $(".selmajorBtn").removeClass("active");
        this.$refs.recomme.getParttimeList();
      } else if (this.workType == "猎头荐才") {
        this.$refs.part.searchInfo.certificate = this.searchCer;
        this.$refs.part.cerList.forEach((element) => {
          if (this.searchCer == element.certificate) {
            this.$refs.part.majorList = element.child;
          }
        });
        this.$refs.part.searchInfo.major = "";
        $(".selmajorBtn").removeClass("active");
        this.$refs.part.getParttimeList();
      }
    },
    // 发布
    postBtn() {
      if (JSON.stringify(this.userInfo) == "{}") {
        this.$message.warning("您还没有登录，现在前往登录界面！");
        setTimeout(() => {
          this.$router.push("/Login");
        }, 1600);
        return;
      }
      if (this.userInfo.u_identity == 1) {
        this.$message.warning("您是人才角色，不能发布职位！");
        return;
      }
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.vipInfo = res.data.resultData;
            if (this.vipInfo.u_surplus_push < 1) {
              this.$message.warning(
                "您的发布次数已用完，您可以前往会员服务购买会员！"
              );
            } else {
              if (this.comId != 0 || this.userInfo.u_company != 0) {
                this.$router.push("/comPage/postType");
              } else {
                this.$message.success(
                  "您还未认证企业，现在前往完善认证企业信息！"
                );
                setTimeout(() => {
                  this.$router.push("comrealSel");
                }, 1200);
              }
            }
          }
        });
      this.getRealeInfo();
    },
    getRealeInfo() {
      // 企业认证信息
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            this.setcomRealeInfo({
              comRealeInfo: res.data.resultData,
            });
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              this.setcomRealeInfo({
                comRealeInfo: res.data.resultData,
              });
            }
          });
      }
      // 企业员工信息
      this.$http.post("company/user/getpersonal").then((res) => {
        if (res.data.resultCode === 1) {
          this.setcomStaffInfo({
            comStaffInfo: res.data.resultData,
          });
        }
      });
    },
  },
  mounted() {
    if (this.userInfo.u_identity == 1) {
      this.userType = false;
    } else if (this.userInfo.u_identity == 2 || this.userInfo.u_identity == 3) {
      this.userType = true;
    }
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
}
.searchInput {
  width: calc(100% - 136px);
}
/deep/.searchBox .el-input__inner {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
}
.el-input-group__append img {
  width: 20px;
}
</style>
