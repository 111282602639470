<!--
 * @Description: 兼职简历列表
 * @Author: huacong
 * @Date: 2021-04-26 14:08:07
 * @LastEditTime: 2021-07-21 11:12:25
 * @LastEditors: huacong
-->
<template>
  <div>
    <section>
      <!-- 筛选条件 -->
      <div class="conditionBox">
        <el-form class="conditionForm" label-width="65px">
          <el-form-item label="分类：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearCer($event)"
              size="mini"
              class="selbtn selcerBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selCer($event, item.id, item.certificate)"
              v-for="item in cerList"
              :key="item.id"
              size="mini"
              :class="
                searchInfo.certificate == item.certificate
                  ? 'selbtn selcerBtn active'
                  : 'selbtn selcerBtn'
              "
            >
              {{ item.certificate }}
            </el-button>
          </el-form-item>
          <el-form-item label="专业：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearmajor($event)"
              size="mini"
              class="selbtn selcerBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selmajor($event, item.certificate)"
              v-for="item in majorList"
              :key="item.id"
              size="mini"
              class="selbtn selmajorBtn"
            >
              {{ item.certificate }}
            </el-button>
          </el-form-item>
          <el-form-item label="省份：" class="proviceBox">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearProvice($event)"
              size="mini"
              class="selbtn selproviceBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selProvice($event, item.value, item.label)"
              v-for="item in proviceData"
              :key="item.value"
              size="mini"
              class="selbtn selproviceBtn"
            >
              {{ item.label }}
            </el-button>
            <el-button
              type="text"
              v-if="morepro"
              @click="moreProbtn"
              class="foldbtn"
              >更多</el-button
            >
            <el-button
              type="text"
              v-if="packpro"
              @click="packProbtn"
              class="foldbtn"
              >收起</el-button
            >
          </el-form-item>
          <el-form-item label="城市：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearCity($event)"
              size="mini"
              class="selbtn selcityBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selCity($event, item.label)"
              v-for="item in cityData"
              :key="item.value"
              size="mini"
              class="selbtn selcityBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
          <el-form-item label="更多：">
            <el-select
              v-model="searchInfo.register"
              placeholder="注册情况"
              size="small"
              class="margin-left-16"
            >
              <el-option label="初始注册" value="初始注册"> </el-option>
              <el-option label="转注册" value="转注册"> </el-option>
              <el-option label="初转不限" value="初转不限"> </el-option>
            </el-select>
            <el-select
              v-model="searchInfo.social"
              placeholder="社保要求"
              size="small"
              class="margin-left-16"
            >
              <el-option label="不限" value="不限"> </el-option>
              <el-option label="社保唯一" value="社保唯一"> </el-option>
            </el-select>
          </el-form-item>
          <div class="text-center">
            <el-button
              type="text"
              style="color:#FF0000"
              v-prevent-repeat-click
              @click="clearSearch"
            >
              清除筛选条件
            </el-button>
          </div>
        </el-form>
      </div>
      <!-- 数据展示区域--表格 -->
      <div class="partTimeBox">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-table :data="partData" class="partTimeTable tableHover">
              <el-table-column width="100px">
                <template slot-scope="scope">
                  <div
                    style="padding-left:10px"
                    @click="gorecomme(scope.row, scope.row.uid)"
                  >
                    <span style="font-weight:700" class="fontHover">
                      {{ scope.row.tal_name.slice(0, 1) }}工
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="360px">
                <template slot-scope="scope">
                  <div
                    class="text-center"
                    @click="gorecomme(scope.row, scope.row.uid)"
                  >
                    <span>
                      {{ scope.row.certificate }}-{{ scope.row.major }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="500px">
                <template slot-scope="scope">
                  <div
                    class="partType"
                    @click="gorecomme(scope.row, scope.row.uid)"
                  >
                    <span>{{ scope.row.zs_price }}万</span>
                    <span>{{ scope.row.register }}</span>
                    <span
                      >{{ scope.row.region_province }}-{{
                        scope.row.region_city
                      }}</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div
                    class="text-right"
                    @click="gorecomme(scope.row, scope.row.uid)"
                    style="padding-right:10px"
                  >
                    <span style="color: #2573F1">{{
                      scope.row.create_time
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                @size-change="sizeChange1"
                @current-change="currentChange1"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import { provinceAndCityData } from "element-china-area-data"; // CodeToText
export default {
  data() {
    return {
      searchIcon: require("@/assets/icon/search-icon.png"),
      noDate: require("@/assets/img/noDate.png"),
      searchCer: "",
      cerList: [],
      majorList: [],
      searchInfo: {},
      proviceData: provinceAndCityData,
      cityData: [],
      partData: [],
      morepro: true,
      packpro: false,
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
    };
  },
  methods: {
    // 获取证书列表
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        if (res.data.resultCode === 1) {
          this.cerList = res.data.resultData;
          this.majorList = res.data.resultData[0].child;
        }
      });
    },
    // 选择证书分类 event:当前按钮  e:该证书在证书列表的id
    selCer(event, e, cer) {
      $(".selcerBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.cerList.forEach((element) => {
        if (e == element.id) {
          this.majorList = element.child;
        }
      });
      this.searchInfo.certificate = cer;
      this.searchInfo.major = "";
      $(".selmajorBtn").removeClass("active");
      this.getParttimeList();
    },
    clearCer(event) {
      $(".selcerBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.certificate = "";
      this.searchInfo.major = "";
      this.getParttimeList();
    },
    // 选择专业
    selmajor(event, major) {
      $(".selmajorBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.major = major;
      this.getParttimeList();
    },
    clearmajor(event) {
      $(".selmajorBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.major = "";
      this.getParttimeList();
    },
    /**
     * @description: 获取省份
     * @param  {event: 当前按钮}
     * @param  {e: 当前选项的值}
     * @param  {provice: 当前选的省份}
     * @return {*}
     */
    selProvice(event, e, provice) {
      $(".selproviceBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.proviceData.forEach((element) => {
        if (e == element.value) {
          this.cityData = element.children;
        }
      });
      this.searchInfo.region_province = provice;
      this.searchInfo.region_city = "";
      $(".selcityBtn").removeClass("active");
      this.getParttimeList();
    },
    clearProvice(event) {
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.region_province = "";
      this.searchInfo.region_city = "";
      this.getParttimeList();
    },
    // 选择城市city
    selCity(event, city) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.region_city = city;
      this.getParttimeList();
    },
    clearCity(event) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.region_city = "";
      this.getParttimeList();
    },
    // 清除筛选
    clearSearch() {
      $(".selcerBtn").removeClass("active");
      $(".selmajorBtn").removeClass("active");
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      this.searchInfo = {};
      this.$parent.searchCer = "";
      this.getParttimeList();
    },
    // 获取人员兼职列表
    getParttimeList(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.$http.post("index/resume/parresume", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getParttimeList(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getParttimeList(e, this.pagesize);
    },
    // 展示更多省份
    moreProbtn() {
      this.morepro = false;
      this.packpro = true;
      $(".proviceBox").css({ height: "auto" });
    },
    // 折叠省份
    packProbtn() {
      this.morepro = true;
      this.packpro = false;
      $(".proviceBox").css({ height: "40px" });
    },
    // 跳转建筑企业详情--和猎头荐才通用
    gorecomme(item, uid) {
      let routeUrl = this.$router.resolve({
        name: "recommeInfo",
        query: {
          id: item.id,
          uid: uid,
          cer: item.certificate,
          major: item.major,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.getCerList();
    this.getParttimeList();
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
}
.searchInput {
  width: calc(100% - 136px);
}
/deep/.searchBox .el-input__inner {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
}
.el-input-group__append img {
  width: 20px;
}
.conditionBox {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
}
/deep/.conditionForm .el-form-item {
  margin-bottom: 0;
  padding: 7px 20px;
  border-bottom: 1px solid #e8e8e8;
}
/deep/.conditionForm .el-form-item__label {
  color: #333;
  font-size: 17px;
  font-weight: bold;
}
.proviceBox {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.foldbtn {
  position: absolute;
  right: -10px;
  top: 0px;
}
.conditionForm .selbtn {
  color: #333;
  font-size: 13px;
}
.el-button--text.color999 {
  color: #999;
}
.selcerBtn,
.selmajorBtn,
.selcityBtn,
.selproviceBtn {
  padding-left: 7px;
  padding-right: 7px;
}
.selcerBtn.active,
.selmajorBtn.active,
.selcityBtn.active,
.selproviceBtn.active {
  background: #2573f1;
  border-radius: 4px;
  color: #fff;
}
.partType span:not(:last-child) {
  margin-right: 40px;
}
.partType span:first-child {
  font-weight: bold;
  font-size: 14px;
  color: #f00;
  display: inline-block;
  width: 90px;
}
.partType span:nth-child(2) {
  display: inline-block;
  width: 70px;
}
.partType span:not(:first-child) {
  color: #999;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
</style>
<style>
.tableHover .el-table__body tr:hover > td {
  background-color: #d9e6ff !important;
}
</style>
